import { useEffect, useState } from "react";
import '../css/task-set.css';

export default function TasksSet({tasks, setTasks}) {

    const [taskOwner, setTaskOwner] = useState('');
    const [taskDescription, setTaskDescription] = useState('');

    let resetFields = () => {
        setTaskOwner('');
        setTaskDescription('');
    };

    let saveTasks = () => {

        if (taskOwner === '' || taskDescription === '') {
            alert('Please fill all fields');
            return;
        }

        if (tasks === null) {
            setTasks([]);
        }

        let tasksCopy = tasks.slice();

        if (tasksCopy === null) {
            tasksCopy = [];
        }

        setTasks([...tasksCopy, {
            owner: taskOwner,
            description: taskDescription,
            date: new Date()
        }]);
    };

    useEffect(() => {
        if (taskOwner === '' || taskDescription === '') {
            return;
        }
        localStorage.setItem('tasks', JSON.stringify(tasks));
        resetFields();
    }, [tasks]);

    return (
        <div className="h-100 col-3 d-flex flex-column justify-content-between p-3 border-primary-subtle rounded task-set-container">
            <div className="form-group mb-3">
                <label>Tasks owner:</label>
                <input type="text" className="form-control" placeholder="Owner" value={taskOwner} onChange={(event) => {setTaskOwner(event.target.value)}}/>
            </div>
            <div className="form-group mb-3">
                <label>Tasks description:</label>
                <input type="text" className="form-control" placeholder="Describe your tasks" value={taskDescription} onChange={(event) => {setTaskDescription(event.target.value)}}/>
            </div>
            <div className="form-group d-flex flex-row justify-content-center">
                <button className="btn btn-md btn-primary" onClick={saveTasks}>
                    Add task
                </button>
            </div>
        </div>
    );

}