import '../css/todo-task.css';

function Task({tasks, setTasks}) {

    let deleteTasks = (event, idx) => {
        console.log(idx);
        let tasksCopy = tasks.slice();
        tasksCopy.splice(idx, 1);
        setTasks(tasksCopy);
        localStorage.setItem('tasks', JSON.stringify(tasksCopy));
    };

    let finalTasks = [];

    try {
        finalTasks = tasks.map((task, idx) => {
            return (
                <li className="list-group-item p-4 d-flex flex-row justify-content-between" key={idx}>
                    Task for {task.owner}. "{task.description}"
                    <button className="btn btn-sm btn-danger align-right" onClick={(event) => {deleteTasks(event, idx)}}>Delete</button>
                </li>
            )
        });
    } catch (e) {}

    if (finalTasks.length === 0) {
        finalTasks = (
            <div className="h-100 d-flex justify-content-center align-items-center">
                <h3>Let's add a new task</h3>
            </div>
        );
    }

    return (
        <>
            {finalTasks}
        </>
    );

}

export default function TaskList({tasks, setTasks}) {
    return (
        <ul className="col-9 list-group pt-0 todo-task-container">
            <Task tasks={tasks} setTasks={setTasks}/>
        </ul>
    );
}