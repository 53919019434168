import { useState, useEffect } from 'react';
import TaskList from './todo-task';
import TasksSet from './task-set';

import '../css/todo-container.css';

export default function TodoContainer() {

    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        let temp = JSON.parse(localStorage.getItem('tasks'));
        if (temp !== undefined || temp !== null) {
            setTasks(temp);
        }
    }, []);

    return (
        <div className="container-fluid p-5 todo-container">
            <div className="row">
                <TasksSet tasks={tasks} setTasks={setTasks}/>
                <TaskList tasks={tasks} setTasks={setTasks}/>
            </div>
        </div>
    );
}