import '../css/header.css';


export default function Header() {
    return (
        <div className='header card'>
            <div className='card-body'>
                <h1>To-do list</h1>
            </div>
        </div>
    );
}